import 'bootstrap';

try {
  const navbarMain = document.querySelector('nav');

  window.addEventListener('scroll', () => {
    if(window.scrollY > 0) {
      navbarMain.classList.add('scrolled');
    } else {
      navbarMain.classList.remove('scrolled');
    }
  })
} catch(e) {
  console.log(e);
}
