try {
  const nfjCookieBar = document.querySelector('.nfj-cookie-banner');
  const btnAcceptCookie = document.querySelector('.btn-accept-cookie');

  console.log('12313');

  const cookieData = document.cookie.match('(^|;) ?cookie-usage-confirm=([^;]*)(;|$)');
  const cookieValue = cookieData ? cookieData[2] : null;

  if (cookieValue !== 'confirmed') {
    nfjCookieBar.classList.add('active');
  }

  btnAcceptCookie.addEventListener('click', () => {
    document.cookie = 'cookie-usage-confirm=confirmed; path=/'

    nfjCookieBar.classList.remove('active');
  });
} catch(error) {
  console.log(error);
}
